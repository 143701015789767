import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { css } from '@emotion/core'
import LogoHor from '../components/LogoHor';
import ContactForm from '../components/ContactForm';

export default (props) => {

    return (
        <div>
            <LogoHor origin={props.location.state ? props.location.state.prevPath : '/'} />
            <div css={css`
                background-image: linear-gradient(to right bottom, #E94841 0%, #E94841 50%, #1169b1 50%, #1169b1 100%);
                display: flex;
                min-height: 90vh;
                @media only screen and (max-width: 26.5625em) {
                    flex-direction: column;
                }
            `}>
                <div css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 95%;
                `}>
                    <picture css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2rem;
                        flex: 1;
                        @media only screen and (max-width: 26.5625em) {
                            padding-top: 8rem;
                        }
                    `}>
                        <img css={css`
                        width: 100%;
                        max-width: 30rem;
                        @media only screen and (max-width: 26.5625em) {
                            width: 80%;
                        }
                    `} src="/images/feria-maroma.png" alt="Feria Maroma"/>
                    </picture>
                    <div css={css`
                        flex: 1;
                        padding: 2rem;
                        @media only screen and (max-width: 26.5625em) {
                            margin-right: 0;
                            margin-bottom: 4.5rem;
                            flex: unset;
                        }
                    `}>
                        <Carousel showThumbs={false} showArrows={true}>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/1.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/2.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/3.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/4.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/5.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/6.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/7.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/8.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/9.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/10.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/11.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/12.jpg" />
                            </div>
                            <div css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 32.5rem;
                                overflow: hidden;
                            `}>
                                <img css={css`
                                    width: 100%;
                                `} src="/images/maroma/13.jpg" />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div css={css`
                    padding: 2.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    @media only screen and (max-width: 26.5625em) {
                        padding: 1rem;
                    }
                `}>
                    <div css={css`
                        width: 95%;
                        max-width: 100rem;
                        margin: 0 auto;
                        p {
                            padding: 1rem;
                            font-size: 1.8rem;
                            color: #fff;
                        }
                    `}>
                        <p>Feria Maroma es la edición más grande del año de Maroma Galería en Movimiento la cual se lleva acabo durante tres días de Art Week CDMX.</p>
                        <p>A diferencia de otras ferias de arte que brindan el mismo espacio de venta a distintas galerías, este proyecto trabaja directamente con los artistas en cuanto a montaje y curaduría, diseñándoles un espacio único y distintivo a cada uno de ellos.</p>
                        <p>Feria Maroma antepone la importancia del arte y su exposición sobre la venta de las obras. Es por eso que no es únicamente una feria de arte, sino también un espacio de exposición.</p>
                        <p>Durante el evento se crean diferentes instalaciones principales cada una de ellas presentando a un solo artista y además una exposición colectiva.</p>
                    </div>
                </div>
            </div>
            <div css={css`
                padding: 4.5rem 2.5rem;
                background-color: black;
                width: 100%;
                display: flex;
            `}>
                <div css={css`
                width: 95%;
                max-width: 40rem;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                h3 {
                    font-size: 2rem;
                    color: #fff;
                    text-align: center;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    .input-item {
                    display: flex;
                    flex-direction: column;
                    padding: 1rem 0;
                    font-size: 1.4rem;
                    color: #fff;
                    input, textarea {
                        margin-top: .5rem;
                        padding: 1rem;
                        background-color: transparent;
                        border: 1px solid #fff;
                        outline: none;
                        color: #fff;
                    }
                    }
                    button {
                    padding: 1rem;
                    background-color: #fff;
                    color: black;
                    text-transform: uppercase;
                    }
                }
                `}>
                    <h3>Conoce más de nuestros eventos</h3>
                    <ContactForm page="Feria Maroma" />
                </div>
            </div>
        </div>
    )
}